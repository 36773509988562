// eslint-disable-next-line no-redeclare,no-unused-vars
function number_format(num, dig, dec, sep) {
  const x = new Array();
  const s = num < 0 ? '-' : '';
  const splitNum = Math.abs(num).toFixed(dig).split('.');
  const r = splitNum[0].split('').reverse();
  for (var i = 1; i <= r.length; i++) {
    x.unshift(r[i - 1]);
    if (i % 3 == 0 && i != r.length) x.unshift(sep);
  }
  return s + x.join('') + (splitNum[1] ? dec + splitNum[1] : '');
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function end(arr) {
  return $(arr).last()[0];
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function str_pad(nr, n, str) {
  var length = n - String(nr).length + 1;
  if (length < 0) return nr;
  return Array(length).join(str || '0') + nr;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function strpos(haystack, needle) {
  if (!haystack || !needle || typeof haystack != 'string') return false;
  var pos = haystack.indexOf(needle);
  if (pos == -1) return false;
  else return pos;
}

// TODO: Consider replacing with String.includes
// eslint-disable-next-line no-redeclare,no-unused-vars
function str_contains(haystack, needle) {
  if (!haystack || !needle || typeof haystack != 'string') return false;
  var pos = haystack.indexOf(needle);
  if (pos == -1) return false;
  else return true;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function str_starts_with(haystack, needle) {
  if (!haystack || !needle || typeof haystack != 'string') return false;
  var pos = haystack.indexOf(needle);
  if (pos === 0) return true;
  else return false;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function str_ends_with(haystack, needle) {
  if (!haystack || !needle || typeof haystack != 'string') return false;
  var pos = haystack.lastIndexOf(needle);
  if (pos == -1) return false;
  else if (pos === haystack.length - needle.length) return true;
  else return false;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function ucfirst(str) {
  if (!str) return '';
  str += '';
  var f = str.charAt(0).toUpperCase();
  return f + str.substr(1);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function nl2br(str, is_xhtml) {
  var breakTag =
    is_xhtml || typeof is_xhtml === 'undefined' ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  );
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function substr_count(haystack, needle, offset, length) {
  let cnt = 0;
  haystack += '';
  needle += '';
  if (isNaN(offset)) {
    offset = 0;
  }
  if (isNaN(length)) {
    length = 0;
  }
  if (needle.length === 0) {
    return false;
  }
  offset--;
  while ((offset = haystack.indexOf(needle, offset + 1)) !== -1) {
    if (length > 0 && offset + needle.length > length) {
      return false;
    }
    cnt++;
  }
  return cnt;
}
